import { Box, HStack } from "@chakra-ui/react";
import React, { FC } from "react";

interface IUserActivityCellProps {
  color: string;
  name: string;
}

export const UserActivityCell: FC<IUserActivityCellProps> = ({
  color,
  name,
}) => {
  return (
    <HStack pl={2}>
      <Box width="10px" height="10px" bg={color} rounded="full" />
      <Box>{name}</Box>
    </HStack>
  );
};
