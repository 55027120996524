import { observer } from "mobx-react";
import { Box, Button, Heading, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  ApiMessageStack,
  ExternalLink,
  FormControlDescription,
  FormControlV2,
} from "components";
import { LEADPRO_WEBSITE_LEGAL_URL } from "constants/external-paths";
import { NavLink, useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "constants/routes";
import { FC, useCallback, useMemo } from "react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import queryString from "query-string";
import * as Yup from "yup";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import { isValidPhone } from "utils/custom-yup-validators.utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { useTurnstileV2 } from "utils/react-hooks/useTurnstileV2.hook";

type TFieldValues = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  accountName?: string;
};

interface Props {
  onSubmitSuccess: (email: string) => void;
}

export const UserSignupForm: FC<Props> = observer(({ onSubmitSuccess }) => {
  const {
    authStore: {
      leadProAuthStore: { signup },
    },
  } = useAppStore();
  const toast = useToast();
  const location = useLocation();
  const { turnstileToken } = useTurnstileV2();

  const additionalSignupData = useMemo(() => {
    return location.search
      ? queryString.parse(location.search, { parseNumbers: true })
      : undefined;
  }, [location]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .required(REQUIRED_FIELD),
      firstName: Yup.string().required(REQUIRED_FIELD),
      lastName: Yup.string().required(REQUIRED_FIELD),
      phoneNumber: Yup.string()
        .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
        .required(REQUIRED_FIELD),
      ...(!!additionalSignupData && {
        accountName: Yup.string().required(REQUIRED_FIELD),
      }),
    });
  }, [additionalSignupData]);

  const initialValues: TFieldValues = useMemo(() => {
    return {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      ...(!!additionalSignupData && { accountName: "" }),
    };
  }, [additionalSignupData]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async ({
      email,
      firstName,
      lastName,
      phoneNumber,
      accountName,
    }: TFieldValues) => {
      try {
        if (!!turnstileToken) {
          const signupData = {
            email,
            firstName,
            lastName,
            phoneNumber,
            additionalSignupData: !!additionalSignupData
              ? {
                  ...additionalSignupData,
                  accountName,
                }
              : undefined,
          };
          await signup(signupData, turnstileToken);
          onSubmitSuccess(email);
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [signup, toast, additionalSignupData, turnstileToken, onSubmitSuccess]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box textAlign="center" mb={6}>
        <Heading fontSize="2xl" lineHeight="none">
          Create your account
        </Heading>
      </Box>
      <Box mb={3}>
        <FormControlV2<TFieldValues>
          name={"email"}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Email address",
          }}
        />
      </Box>
      <Box mb={3}>
        <FormControlV2<TFieldValues>
          name={"firstName"}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "First name",
          }}
        />
      </Box>
      <Box mb={3}>
        <FormControlV2<TFieldValues>
          name={"lastName"}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Last name",
          }}
        />
      </Box>
      <Box mb={3}>
        <FormControlV2<TFieldValues>
          name={"phoneNumber"}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Phone number",
          }}
        />
      </Box>
      {!!additionalSignupData && (
        <Box mb={3}>
          <FormControlV2<TFieldValues>
            name={"accountName"}
            control={control}
            type={FormControlsTypeEnum.TEXT}
            additionalProps={{
              placeholder: "Account name",
            }}
          />
        </Box>
      )}
      <FormControlDescription mb={2}>
        By creating a LeadPro account you agree to our{" "}
        <ExternalLink
          href={LEADPRO_WEBSITE_LEGAL_URL}
          textDecoration="underline"
          label="terms and conditions."
        />
      </FormControlDescription>
      <Button
        type={"submit"}
        width={"full"}
        colorScheme={"blue"}
        isDisabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
      >
        Create account
      </Button>
      <Box mt={2}>
        <NavLink
          to={LOGIN_ROUTE}
          style={{
            display: "block",
          }}
        >
          <Button width={"full"} variant="outline" colorScheme={"blue"}>
            Back to login
          </Button>
        </NavLink>
      </Box>
    </form>
  );
});
