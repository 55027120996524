import { TurnstileV2Context } from "components/turnstile-v2/TurnstileV2";
import React from "react";

export const useTurnstileV2 = () => {
  const context = React.useContext(TurnstileV2Context);
  if (context === undefined) {
    throw new Error("useTurnstileV2 must be used within a TurnstileV2");
  }

  return context;
};
