import { ILeadBasicBlockDefinition } from "./blocks/LeadBasicInfoBlock";
import { LeadValuationBlockDefinition } from "./blocks/LeadValuationBlock";
import { ILeadConnectedLeadsBlockDefinition } from "./blocks/LeadConnectedLeadsBlock/LeadConnectedLeadsBlock";
import { LeadBookingRequestBlockDefinition } from "./blocks/LeadBookingRequestBlock";
import { LeadMetaBlockDefinition } from "./blocks/LeadMetaBlock";
import { LeadPortalBlockDefinition } from "./blocks/LeadPortalBlock";
import { LeadQuestionnaireBlockDefinition } from "./blocks/LeadQuestionnaireBlock";
import { ILeadSettingsBlockDefinition } from "./blocks/LeadSettingsBlock/LeadSettingsBlock";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { isEmpty } from "lodash";
import { ILeadBlockDefinition, LeadBlockTypes } from "utils/hoc/leadBlock.hoc";
import { ILeadUTMBlockDefinition } from "./blocks/LeadUTMBlock";
import { ILeadBYMDataBlockDefinition } from "./blocks/LeadBYMDataBlock";

export const leadBlockDefinitionFactory = (
  accountStore: UserAccountStore,
  leadStore: AccountLeadStore
): ILeadBlockDefinition[] => {
  const lead = leadStore.lead;
  const portalsMap = accountStore.portalsMap;
  const leadSourcesMapBySource =
    accountStore.accountLeadSourcesStore.leadSourcesMapBySource;
  const accountLogoUrl = accountStore.account.logoUrl;
  const definitions = [];
  const connectedLeads = lead.person?.leads;
  const additionalData = lead.additionalData;
  const {
    enquiry,
    saleEstimation,
    rentEstimation,
    valuationAppointmentAvailability,
    questionnaire,
    utm,
  } = additionalData;

  const basicProps = {
    accountStore,
    leadStore,
  };

  const leadSettingsBlockDefinition: ILeadSettingsBlockDefinition = {
    ...basicProps,
    blockType: LeadBlockTypes.SettingsBlock,
  };
  definitions.push(leadSettingsBlockDefinition);

  const basicBlockDefinition: ILeadBasicBlockDefinition = {
    ...basicProps,
    blockType: LeadBlockTypes.BasicBlock,
    createdAt: lead.createdAt,
    source: lead.source,
    person: lead.person,
    type: lead.type,
    enquiry: lead.additionalData?.enquiry,
    office: lead.office,
    leadReferredByAccount: lead.leadReferredByAccount,
    leadReferredByOffice: lead.leadReferredByOffice,
    accountLogo: accountLogoUrl,
    portalsMap,
    leadSourcesMapBySource,
    journeys: lead.journeys,
    urlSync: lead.urlSyncData?.url,
  };

  definitions.push(basicBlockDefinition);

  if (!!lead.additionalData.bym) {
    const bymBlockDefinition: ILeadBYMDataBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.BYMBlock,
      bym: lead.additionalData.bym,
    };

    definitions.push(bymBlockDefinition);
  }

  const hours = valuationAppointmentAvailability?.hours;
  if (hours) {
    const leadBookingRequestBlockDefinition: LeadBookingRequestBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.BookingRequest,
      type: lead.type,
      hours,
    };
    definitions.push(leadBookingRequestBlockDefinition);
  }

  if (!!enquiry) {
    const propertyBedroomsValue =
      lead.source === LeadSourceEnum.ivt
        ? enquiry.propertyBedrooms || "Studio"
        : enquiry.propertyBedrooms;

    const portalBlockDefinition: LeadPortalBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.PortalBlock,
      propertyAddress: enquiry.propertyAddress || enquiry.address,
      propertyPostcode: enquiry.propertyPostcode || enquiry.currentPostcode,
      propertyBedrooms: propertyBedroomsValue,
      propertyBathrooms: enquiry.propertyBathrooms,
      propertyReceptions: enquiry.propertyReceptions,
      listingUrl: enquiry.listingUrl,
      rawPropertyReference: enquiry.rawPropertyReference,
      portalEnquiryId: enquiry.portalEnquiryId,
      additionalInfo: enquiry.additionalInfo,
      message: enquiry.message,
    };
    definitions.push(portalBlockDefinition);
  }

  const sourceDetailsBlockDefinition = {
    ...basicProps,
    blockType: LeadBlockTypes.SourceBlock,
    source: lead.source,
    leadReferredByAccount: lead.leadReferredByAccount,
    leadReferredByOffice: lead.leadReferredByOffice,
    portalsMap,
    leadSourcesMapBySource,
    accountLogo: accountLogoUrl,
    enquiry,
  };
  definitions.push(sourceDetailsBlockDefinition);

  if (!!enquiry && (!isEmpty(saleEstimation) || !isEmpty(rentEstimation))) {
    const valuationBlockDefinition: LeadValuationBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.ValuationBlock,
      source: lead.source,
      estSalesPrice: saleEstimation?.est,
      minSalesPrice: saleEstimation?.min,
      maxSalesPrice: saleEstimation?.max,
      estRentPrice: rentEstimation?.est,
      minRentPrice: rentEstimation?.min,
      maxRentPrice: rentEstimation?.max,
      exactPrice: enquiry.propertyPrice,
    };
    definitions.push(valuationBlockDefinition);
  }

  if (questionnaire) {
    const questionnaireBlock: LeadQuestionnaireBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.QuestionnaireBlock,
      questions: questionnaire,
    };

    definitions.push(questionnaireBlock);
  }

  if (enquiry?.meta) {
    const questionnaireBlock: LeadMetaBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.MetaBlock,
      meta: enquiry.meta,
    };

    definitions.push(questionnaireBlock);
  }

  if (Array.isArray(connectedLeads) && connectedLeads.length > 1) {
    const connectedLeadsBlockDefinition: ILeadConnectedLeadsBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.ConnectedLeadsBlock,
      leads: connectedLeads || [],
    };
    definitions.push(connectedLeadsBlockDefinition);
  }

  if (!!utm && !isEmpty(utm)) {
    const utmBlock: ILeadUTMBlockDefinition = {
      ...basicProps,
      blockType: LeadBlockTypes.UTMBlock,
      utm: utm,
    };

    definitions.push(utmBlock);
  }

  return definitions;
};
