import { AppStore } from "../../../App.store";
import {
  TUserActivityStatsDataRaw,
  TLeadsByUserRaw,
  TUserLeaderboardDataRaw,
  TUserStatsData,
  TUserLoginStat,
  AccountUserStatsExtended,
  TUserLeaderboardExtended,
} from "types/account-analytics.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TSerializedDateRange } from "types/date.type";
import { AnalyticsApi } from "api/analytics.api";
import { TUserBasicData } from "types/user-data.type";
import { AccountUsersApi } from "api/account-users.api";
import { Dictionary, keyBy } from "lodash";
import { AccountAnalyticsFiltersStore } from "./AccountAnalyticsFilters.store";
import { generateDistinctColors } from "utils/color.utils";
import { getUserFullName } from "utils/account-user.utils";

const analyticsApi = new AnalyticsApi();
const accountUsersApi = new AccountUsersApi();
const DEFAULT_COLOR = "#000000";

export class AccountUserAnalyticsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly basicAccountUsers: IObservableArray<TUserBasicData>;
  private userLeaderboardRawData: IObservableArray<TUserLeaderboardDataRaw>;
  private leadsByUserRawData: IObservableArray<TLeadsByUserRaw>;
  private userActivityRawData: TUserActivityStatsDataRaw | null;
  public accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore;

  constructor(
    root: AppStore,
    accountId: number,
    accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountAnalyticsFiltersStore = accountAnalyticsFiltersStore;
    this.leadsByUserRawData = observable.array<TLeadsByUserRaw>();
    this.userLeaderboardRawData = observable.array<TUserLeaderboardDataRaw>();
    this.userActivityRawData = null;
    this.basicAccountUsers = observable.array<TUserBasicData>();
  }

  public get userActivityRawStats() {
    return this.userActivityRawData;
  }

  public get accountUserExtended(): AccountUserStatsExtended[] {
    const colors = generateDistinctColors(this.basicAccountUsers.length);
    return this.basicAccountUsers.map((user, index) => ({
      ...user,
      fullName:
        getUserFullName(user.firstName, user.lastName, user.email) ||
        user.email,
      color: colors[index] ?? DEFAULT_COLOR,
    }));
  }

  public get accountUserExtendedMap(): Dictionary<AccountUserStatsExtended> {
    return keyBy(this.accountUserExtended, user => user.id);
  }

  public get accountUsersBasicInfo() {
    return this.basicAccountUsers.slice();
  }

  public get accountUserOptions() {
    return this.basicAccountUsers.map(user => ({
      value: user.id,
      label: user.email,
    }));
  }

  public get userActivityByUser() {
    return keyBy(this.usersLoginActivity, "id");
  }

  public get statsDataFilteredByUsers(): Record<number, TUserStatsData> | null {
    const rawData = this.userActivityRawStats;
    if (!rawData) return null;

    return this.accountUserExtended.reduce((acc, user) => {
      const stats = rawData.leadEventsByUserByDay.map(({ date, events }) => {
        const userEvent = events.find(event => event.userId === user.id);
        return { date, count: userEvent?.count ?? 0 };
      });

      acc[user.id] = {
        ...user,
        stats,
        total: stats.reduce((sum, { count }) => sum + count, 0),
        lastLoginAt: this.userActivityByUser[user.id]?.lastLoginAt ?? null,
      };

      return acc;
    }, {} as Record<number, TUserStatsData>);
  }

  public get userLeaderboardData(): TUserLeaderboardExtended[] {
    if (!this.userLeaderboardRawData.length) return [];
    return this.userLeaderboardRawData.map(data => {
      const user = this.accountUserExtendedMap[data.user.id];
      return {
        stats: data.stats,
        ...user,
      };
    });
  }

  public get leadsByUser() {
    if (!this.leadsByUserRawData.length) return [];
    return this.leadsByUserRawData.map(data => {
      const user = this.accountUserExtendedMap[data.userId];
      return {
        stats: data.stats,
        ...user,
      };
    });
  }

  public get leadEventsByUserByDay() {
    if (!this.userActivityRawData) return [];
    return this.userActivityRawData.leadEventsByUserByDay;
  }

  public get usersLoginActivity(): TUserLoginStat[] {
    if (!this.userActivityRawData?.usersByLoginDate) return [];
    return this.userActivityRawData.usersByLoginDate.map(userLogin => {
      const user = this.accountUserExtendedMap[userLogin.id];
      return {
        ...user,
        lastLoginAt: userLogin.lastLoginAt,
      };
    });
  }

  private setLeaderboardStats(leaderboardData: TUserLeaderboardDataRaw[]) {
    this.userLeaderboardRawData.replace(leaderboardData);
  }

  public async loadAnalyticsData(dateRange: TSerializedDateRange) {
    const data = await Promise.all([
      analyticsApi.getLeadsByUserStats(this.accountId, dateRange),
      analyticsApi.getUsersLeaderboardStats(this.accountId, dateRange),
      analyticsApi.getUserActivityStats(this.accountId, dateRange),
    ]);

    this.setLeadsByUserStats(data[0]);
    this.setLeaderboardStats(data[1]);
    this.setUserActivity(data[2]);
  }

  public setUserActivity(userActivity: TUserActivityStatsDataRaw) {
    this.userActivityRawData = userActivity;
  }

  private setLeadsByUserStats(leadsByUser: TLeadsByUserRaw[]) {
    this.leadsByUserRawData.replace(leadsByUser);
  }

  private setUserBasicData(users: TUserBasicData[]) {
    this.basicAccountUsers.replace(users);
  }

  public async fetchAccountUsersBasicData() {
    const users = await accountUsersApi.fetchAccountUsersBasicData(
      this.accountId
    );
    this.setUserBasicData(users);
  }
}
