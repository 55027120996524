import { ApiMessageStack } from "components";
import React, { FC, useEffect, useMemo, useState } from "react";
import { RightSideLayout } from "routes/dashboard/components";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { Box, useToast } from "@chakra-ui/react";
import { TabsGroupLayout } from "../../components/Layouts/TabsGroupLayout";
import { Leaderboards } from "./components/Leaderboards/Leaderboards";
import { FilterableAnalyticsView } from "./components/FilterableAnalyticsView";
import { LeadAttributionReporting } from "./components/LeadAttributionReporting/LeadAttributionReporting";
import { AnalyticsFilterTypesEnum } from "./components/AnalyticsFiltersRow/AnalyticsFiltersRow";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { UserReporting } from "./components/UserReporting/UserReporting";
import { UserRoleEnum } from "../../../../enums/user-role.enum";

interface IProps {
  accountStore: UserAccountStore;
}

export const Analytics: FC<IProps> = observer(({ accountStore }) => {
  const toast = useToast();
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);

  const {
    account,
    accountOfficesStore: { fetchAccountOffices },
    accountOfficeGroupsStore: { fetchAllAccountOfficeGroups },
    accountAnalyticsStore: { resetAnalyticStores },
    accountIntegrationsStore: {
      fetchAccountIntegrations,
      hasEcosystemIntegration,
    },
  } = accountStore;

  const hasAdminLeaderAccess = useMemo(
    () => [UserRoleEnum.ADMIN, UserRoleEnum.LEADER].includes(account.roleId),
    [account]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await Promise.all([
          fetchAccountOffices(),
          fetchAllAccountOfficeGroups(),
          fetchAccountIntegrations(),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };
    fetchData();

    return () => {
      resetAnalyticStores();
    };
  }, [
    toast,
    fetchAccountOffices,
    fetchAllAccountOfficeGroups,
    fetchAccountIntegrations,
    resetAnalyticStores,
  ]);

  const tabsGroupLayoutConfig = useMemo(() => {
    const config = [
      {
        tabName: "Leaderboards",
        tabHash: "#leaderboards",
        tabComponent: (
          <FilterableAnalyticsView
            showFilters={[
              AnalyticsFilterTypesEnum.OFFICES,
              AnalyticsFilterTypesEnum.DATE_RANGE,
            ]}
            accountStore={accountStore}
          >
            <Leaderboards accountStore={accountStore} />
          </FilterableAnalyticsView>
        ),
      },
    ];

    if (hasAdminLeaderAccess) {
      config.push({
        tabName: "User Reporting (pilot)",
        tabHash: "#user-reporting",
        tabComponent: (
          <FilterableAnalyticsView
            showFilters={[AnalyticsFilterTypesEnum.DATE_RANGE]}
            accountStore={accountStore}
          >
            <UserReporting accountStore={accountStore} />
          </FilterableAnalyticsView>
        ),
      });
    }

    if (hasEcosystemIntegration) {
      config.push({
        tabName: "Lead attribution reporting (pilot)",
        tabHash: "#lead-attribution-reporting",
        tabComponent: (
          <FilterableAnalyticsView
            showFilters={[AnalyticsFilterTypesEnum.DATE_RANGE]}
            accountStore={accountStore}
          >
            <LeadAttributionReporting accountStore={accountStore} />
          </FilterableAnalyticsView>
        ),
      });
    }

    return config;
  }, [accountStore, hasEcosystemIntegration, hasAdminLeaderAccess]);

  return (
    <RightSideLayout>
      <Box
        position={"relative"}
        height={"100%"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        overflow={"auto"}
      >
        <TabsGroupLayout
          overrideMaxW={"100%"}
          config={tabsGroupLayoutConfig}
          loadingStatus={loadingStatus}
        />
      </Box>
    </RightSideLayout>
  );
});
