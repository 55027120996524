import { orderBy } from "lodash";
import { TUserStatsData } from "../types/account-analytics.type";

export function orderActivityByDate<K extends keyof TUserStatsData>(
  userIds: number[],
  data: Record<number, TUserStatsData>,
  key: K,
  order: "asc" | "desc" = "desc"
): TUserStatsData[] {
  return orderBy(
    userIds.map(id => data[id]),
    user => (!!user[key] ? new Date(String(user[key])).valueOf() : -Infinity),
    order
  );
}
