import { TSerializedDateRange } from "types/date.type";
import { BaseApi } from "./base.api";
import {
  TAccountStatsDataRaw,
  TUserActivityStatsDataRaw,
  TLeadsByOffice,
  TLeadsByUserRaw,
  TOfficeLeaderboardDataPoint,
  TUserLeaderboardDataRaw,
} from "types/account-analytics.type";

export class AnalyticsApi extends BaseApi {
  async getStats(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TAccountStatsDataRaw> {
    return this.get(`/accounts/${accountId}/stats`, {
      params: {
        from: dateRange.startDate || undefined,
        to: dateRange.endDate || undefined,
      },
    });
  }

  async getTotalLeadContacts(
    accountId: number,
    dateRange: TSerializedDateRange,
    officeIds?: number[]
  ): Promise<number> {
    const { totalContacts } = await this.get(
      `/accounts/${accountId}/stats/contacts-by-office`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
          officeIds,
        },
      }
    );

    return totalContacts;
  }

  async getLeaderboardStats(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TOfficeLeaderboardDataPoint[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/offices-leaderboard`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }

  async getUsersLeaderboardStats(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TUserLeaderboardDataRaw[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/users-leaderboard`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }

  async getLeadsByUserStats(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TLeadsByUserRaw[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/leads-by-user`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }

  async getUserActivityStats(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TUserActivityStatsDataRaw> {
    return this.get(`/accounts/${accountId}/stats/user-activity`, {
      params: {
        from: dateRange.startDate || undefined,
        to: dateRange.endDate || undefined,
      },
    });
  }

  async getLeadsByOfficeStats(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TLeadsByOffice[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/leads-by-office`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }
}
