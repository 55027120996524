import { ITableV2Column } from "components/table-v2/table-v2";
import { TUserLoginStat } from "types/account-analytics.type";
import React from "react";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { UserActivityCell } from "../cells/UserActivityCell";
import { LoginAtDateCell } from "../cells/LoginAtDateCell";

export const userLoginActivityColumnDef = (): ITableV2Column<
  TUserLoginStat
>[] => [
  {
    accessor: "firstName",
    Header: <DefaultHeaderCell>User</DefaultHeaderCell>,
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <DefaultRowCell>
          <UserActivityCell color={row.color} name={row.fullName} />
        </DefaultRowCell>
      );
    },
  },
  {
    accessor: "lastLoginAt",
    Header: <DefaultHeaderCell>Most Recent Login</DefaultHeaderCell>,
    disableSortBy: false,
    width: "150px",
    Cell: ({ row }: any) => (
      <DefaultRowCell>
        <LoginAtDateCell date={row.lastLoginAt} />
      </DefaultRowCell>
    ),
  },
];
