import { ITableV2Column } from "components/table-v2/table-v2";
import { TUserLeaderboardStats } from "utils/user-leaderboard.utils";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { getScoreColor } from "utils/office-leaderboard.utils";
import { Text } from "@chakra-ui/react";
import { ScoreHeader } from "../../cells/ScoreHeader";
import { ResponseTimeTooltip } from "../../tooltips/ResponseTimeTooltip";
import { ConversionRateTooltip } from "../../tooltips/ConversionRateTooltip";
import { ScoreCell } from "../../cells/ScoreCell";

export const userLeaderboardColumnDef = (): ITableV2Column<
  TUserLeaderboardStats
>[] => {
  return [
    {
      accessor: "id",
      width: "40px",
      disableSortBy: true,
      Header: <DefaultHeaderCell>#</DefaultHeaderCell>,
      Cell: ({ row, index }: any) => (
        <DefaultRowCell>{index + 1}</DefaultRowCell>
      ),
    },
    {
      accessor: "email",
      disableSortBy: true,
      Header: <DefaultHeaderCell>User</DefaultHeaderCell>,
      Cell: ({ row }: any) => <DefaultRowCell>{row.email}</DefaultRowCell>,
    },
    {
      accessor: "averageResponseTime",
      Header: (
        <DefaultHeaderCell>
          <ScoreHeader
            title={"Response time"}
            tooltip={<ResponseTimeTooltip />}
          />
        </DefaultHeaderCell>
      ),
      Cell: ({ row }: any) => (
        <DefaultRowCell>
          <ScoreCell
            value={row.averageResponseTime}
            percent={row.averageResponseTime}
            color={getScoreColor(row.averageResponseTime)}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "conversionRate",
      Header: (
        <DefaultHeaderCell>
          <ScoreHeader
            title={"Business won"}
            tooltip={<ConversionRateTooltip />}
          />
        </DefaultHeaderCell>
      ),
      Cell: ({ row }: any) => (
        <DefaultRowCell>
          <ScoreCell
            value={`${row.conversionRate}%`}
            percent={row.conversionRate}
            color={getScoreColor(row.conversionRate)}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "overallScore",
      Header: (
        <DefaultHeaderCell>
          <ScoreHeader
            title={"Overall score"}
            tooltip={
              <Text mb={2} textAlign={"justify"}>
                Your overall score is a combination of your Response Time points
                and your Business Won percentage. They are combined to give you
                an overall total score, which users are then ranked by.
              </Text>
            }
          />
        </DefaultHeaderCell>
      ),
      Cell: ({ row }: any) => (
        <DefaultRowCell p={3}>
          <ScoreCell
            value={row.overallScore}
            percent={row.overallScore}
            color={getScoreColor(row.overallScore)}
          />
        </DefaultRowCell>
      ),
    },
  ];
};
