import React from "react";
import { ApiRequestStatusEnum } from "../../enums/api-request-status.enum";

export enum TableV2SortByDirection {
  ASC = "asc",
  DESC = "desc",
}

export interface ITableV2SortBy<T> {
  field: keyof T;
  direction: TableV2SortByDirection;
}

export interface ITableV2Column<T> {
  accessor: keyof T;
  Header: React.ReactNode;
  Cell: ({ row }: { row: T; index: number }) => React.ReactNode;
  excludeFromGlobalFilter?: boolean;
  disableSortBy?: boolean;
  width?: string;
}

export interface ITableV2Props<T> {
  loadingStatus?: ApiRequestStatusEnum;
  dataSource: T[];
  columns: Array<ITableV2Column<T>>;
  globalFilterInputPlaceholder?: string;
  pageSize?: number;
  striped?: boolean;
  onSortByChange?: (sortBy: ITableV2SortBy<T> | null) => void;
  onRowClick?: (row: T) => void;
  additionalActions?: React.ReactNode;
}
