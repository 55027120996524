import { Box } from "@chakra-ui/react";
import { DATE_FORMAT } from "constants/date";
import moment from "moment";
import React, { FC, useMemo } from "react";

interface ILoginAtDateCellProps {
  date: string | null;
}

const DEFAULT = "NO LOGIN";

export const LoginAtDateCell: FC<ILoginAtDateCellProps> = ({ date }) => {
  const from = useMemo(() => {
    if (!date) return null;
    return moment(date).fromNow();
  }, [date]);

  const calendarDate = useMemo(() => {
    if (!date) return null;
    return moment(date)
      .format(DATE_FORMAT)
      .toUpperCase();
  }, [date]);

  if (!date || !from || !calendarDate)
    return (
      <Box display={"flex"} my={2} height={"100%"} alignItems={"center"}>
        {DEFAULT}
      </Box>
    );

  return (
    <Box>
      <Box>{calendarDate}</Box>
      <Box fontSize={"xs"} color={"leadpro.400"}>
        {from}
      </Box>
    </Box>
  );
};
