import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { ExternalLink } from "components";
import { LeadBlockHeader } from "../LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlockBody";
import {
  LeadInfoRow,
  LeadInfoRowLabel,
  LeadInfoRowValue,
} from "../LeadInfoRow";

export interface LeadPortalBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.PortalBlock;
  propertyAddress?: string;
  propertyPostcode?: string;
  propertyBedrooms?: number | string;
  propertyBathrooms?: number | string;
  propertyReceptions?: number | string;
  listingUrl?: string;
  rawPropertyReference?: string;
  portalEnquiryId?: number;
  additionalInfo?: string;
  message?: string;
}

export const LeadPortalBlock = leadBlockHoc<LeadPortalBlockDefinition>(
  props => {
    const {
      leadBlockDefinition: {
        propertyAddress,
        propertyPostcode,
        propertyBedrooms,
        propertyBathrooms,
        propertyReceptions,
        listingUrl,
        rawPropertyReference,
        portalEnquiryId,
        additionalInfo,
        message,
      },
    } = props;

    const messageText = message
      ?.replaceAll(/<br *\/*>/g, "\n")
      .replaceAll(/<[^>]+>/g, "");

    return (
      <>
        <LeadBlockHeader>Target property details</LeadBlockHeader>
        <LeadBlockBody>
          {!!propertyAddress && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Address</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {listingUrl ? (
                  <ExternalLink href={listingUrl} label={propertyAddress} />
                ) : (
                  propertyAddress
                )}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!propertyPostcode && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Postcode</LeadInfoRowLabel>
              <LeadInfoRowValue>{propertyPostcode}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!propertyBedrooms && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Bedrooms</LeadInfoRowLabel>
              <LeadInfoRowValue>{propertyBedrooms}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!propertyBathrooms && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Bathrooms</LeadInfoRowLabel>
              <LeadInfoRowValue>{propertyBathrooms}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!propertyReceptions && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Receptions</LeadInfoRowLabel>
              <LeadInfoRowValue>{propertyReceptions}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!rawPropertyReference && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Reference</LeadInfoRowLabel>
              <LeadInfoRowValue>{rawPropertyReference}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!portalEnquiryId && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Portal ID</LeadInfoRowLabel>
              <LeadInfoRowValue>{portalEnquiryId}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!additionalInfo && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Additional info</LeadInfoRowLabel>
              <LeadInfoRowValue>{additionalInfo}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!message && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Message</LeadInfoRowLabel>
              <LeadInfoRowValue>{messageText}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
        </LeadBlockBody>
      </>
    );
  }
);
