export const generateDistinctColors = (n: number): string[] => {
  const step = 360 / n;
  const colors: string[] = [];

  for (let i = 0; i < n; i++) {
    const hue = Math.round(step * i);
    const color = `hsl(${hue}, 65%, 50%)`;
    colors.push(color);
  }

  for (let i = colors.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [colors[i], colors[j]] = [colors[j], colors[i]];
  }

  return colors;
};
