import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { AccountUserAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsUser.store";
import { formatDateForChart } from "utils/date.utils";
import { GraphCard } from "components/stats/GraphCard";
import { Chart, ChartType } from "components/chart";
import { TableV2 } from "components";
import { TUserDateStat, TUserStatsData } from "types/account-analytics.type";
import { userActivityByDayColumnDef } from "./userActivityByDayColumnDef";
import { UserActivityUserFilter } from "./UserActivityUserFilter";
import { orderActivityByDate } from "utils/user-activity-by-day.utils";

interface IProps {
  accountUserAnalyticsStore: AccountUserAnalyticsStore;
}

const DEFAULT_SELECTED_USERS = 5;
const DEFAULT_PAGE_SIZE = 5;

export const UserActivityByDay: FC<IProps> = observer(
  ({ accountUserAnalyticsStore }) => {
    const {
      leadEventsByUserByDay,
      accountUsersBasicInfo,
    } = accountUserAnalyticsStore;

    const dailyLeadEventsByUser = accountUserAnalyticsStore.statsDataFilteredByUsers!;

    const [selectedUsers, setSelectedUsers] = useState<number[]>(
      orderActivityByDate(
        accountUsersBasicInfo.map(user => user.id),
        dailyLeadEventsByUser,
        "lastLoginAt",
        "desc"
      )
        .map(user => user.id)
        .slice(0, DEFAULT_SELECTED_USERS)
    );

    const filteredData = useMemo(() => {
      return orderActivityByDate(
        selectedUsers,
        dailyLeadEventsByUser,
        "lastLoginAt",
        "desc"
      );
    }, [dailyLeadEventsByUser, selectedUsers]);

    const chartSeries = useMemo(() => {
      return filteredData.map(user => {
        return user.stats.map((item: TUserDateStat) => item.count);
      });
    }, [filteredData]);

    const chartColors = useMemo(() => {
      return filteredData.map(data => data.color);
    }, [filteredData]);

    const labels = useMemo(
      () =>
        leadEventsByUserByDay.map((item: any) => formatDateForChart(item.date)),
      [leadEventsByUserByDay]
    );

    const handleUserChange = useCallback(
      (value: number[]) => {
        setSelectedUsers(value);
      },
      [setSelectedUsers]
    );

    const handleRemoveUser = useCallback(
      (userId: number) => {
        const index = selectedUsers.findIndex(id => id === userId);
        if (index > -1) {
          setSelectedUsers(selectedUsers.filter(id => id !== userId));
        }
      },
      [setSelectedUsers, selectedUsers]
    );

    const seriesNames = useMemo(() => {
      return filteredData.map(user => user.fullName);
    }, [filteredData]);

    const columnDef = useMemo(() => {
      return userActivityByDayColumnDef(handleRemoveUser);
    }, [handleRemoveUser]);

    return (
      <GraphCard title={`User activity by day`}>
        <Box mb={5}>
          <Chart
            type={ChartType.Line}
            series={chartSeries}
            labels={labels}
            colors={chartColors}
            seriesNames={seriesNames}
          />
        </Box>
        <Box mb={5}>
          <TableV2<TUserStatsData>
            dataSource={filteredData}
            columns={columnDef}
            pageSize={DEFAULT_PAGE_SIZE}
            striped
          />
        </Box>

        <Box mb={5}>
          <UserActivityUserFilter
            accountUserAnalyticsStore={accountUserAnalyticsStore}
            selectedUsers={selectedUsers}
            onChange={handleUserChange}
          />
        </Box>
      </GraphCard>
    );
  }
);
