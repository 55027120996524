import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { Box } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { UserReportingContent } from "./UserReportingContent";

interface IProps {
  accountStore: UserAccountStore;
}

export const UserReporting: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(
    ApiRequestStatusEnum.SUCCESS
  );

  const { accountAnalyticsStore } = accountStore;

  const accountAnalyticsUserStore =
    accountAnalyticsStore.accountUserAnalyticsStore;

  const {
    accountAnalyticsFiltersStore: { dateRange },
    fetchAccountUsersBasicData,
    loadAnalyticsData,
    userActivityRawStats,
  } = accountAnalyticsUserStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await Promise.all([
          fetchAccountUsersBasicData(),
          loadAnalyticsData(dateRange),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [
    accountAnalyticsUserStore,
    dateRange,
    fetchAccountUsersBasicData,
    loadAnalyticsData,
  ]);

  if (loadingStatus === ApiRequestStatusEnum.ERROR) {
    return <Error />;
  }

  return (
    <>
      {loadingStatus === ApiRequestStatusEnum.PENDING && (
        <Box
          display={"flex"}
          position={"absolute"}
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={10}
        >
          <Loader />
        </Box>
      )}
      {!!userActivityRawStats && (
        <UserReportingContent analyticsStore={accountAnalyticsStore} />
      )}
    </>
  );
});
