import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  TIntegrationOffice,
  TIntegrationOfficeRelationData,
} from "types/integration-sync-office.type";
import {
  TLeadOpenViewAddress,
  TOpenViewContactData,
  TOpenViewOffice,
  TOpenViewOfficeRelation,
} from "types/openview.type";
import {
  normaliseAndSplitAddress,
  shiftPropertyName,
  shiftPropertyNumber,
} from "./integration-address.utils";

export const openViewOfficeDataToIntegrationOfficeDataMapper = (
  openViewOffices: TOpenViewOffice[]
): TIntegrationOffice[] => {
  return openViewOffices.map(openViewOffice => ({
    id: openViewOffice.id.toString(),
    name: openViewOffice.name,
  }));
};

export const mapOpenViewOfficeRelationsToIntegrationOfficeRelations = (
  openViewOfficeRelations: TOpenViewOfficeRelation[]
) => {
  return openViewOfficeRelations.map(openViewOfficeRelation => ({
    officeId: openViewOfficeRelation.officeId,
    branchId: openViewOfficeRelation.openViewId,
  }));
};

export const mapIntegrationOfficeRelationsToOpenViewOfficeRelations = (
  relations: TIntegrationOfficeRelationData[]
) => {
  return relations.map(relation => ({
    officeId: relation.officeId,
    openViewId: relation.branchId,
  }));
};

export const getOpenViewSegmentedAddress = (
  address: string | null,
  postcode: string | null
): TLeadOpenViewAddress => {
  const openViewAddress = {
    houseNo: "",
    address1: "",
    address2: "",
    town: "",
    county: "",
    postcode: postcode || "",
    countryName: "",
    fullAddress: address || "",
    shortAddress: "",
  };

  if (!address) {
    return openViewAddress;
  }

  const splitAddress = normaliseAndSplitAddress(address, postcode);
  const propDetails = [
    shiftPropertyName(splitAddress),
    shiftPropertyNumber(splitAddress),
  ];

  openViewAddress.houseNo = propDetails.filter(item => !!item).join(", ");

  [
    openViewAddress.address1,
    openViewAddress.address2,
    openViewAddress.town,
    openViewAddress.county,
  ] = splitAddress.slice();

  return openViewAddress;
};

export const getArchivedState = (
  leadType: LeadTypeEnum,
  data?: TOpenViewContactData
) => {
  if (!data) {
    return false;
  }

  return data.archived.includes(leadType);
};
