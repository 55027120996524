import { stageColors } from "constants/colors";
import React from "react";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { HStack, Tag, TagLabel, TagProps } from "@chakra-ui/react";
import { LeadStageLabel } from "constants/lead-stage-label";
import {
  AllBusinessIcon,
  BusinessIcon,
  InProgressIcon,
  NoBusinessIcon,
  UnreadIcon,
} from "components/icons";

interface ILeadStageTagProps extends TagProps {
  stage: LeadStageEnum;
}

export const stageIconMap: Record<LeadStageEnum, JSX.Element> = {
  [LeadStageEnum.UNREAD]: (
    <UnreadIcon boxSize={3} color={stageColors[LeadStageEnum.UNREAD]} />
  ),
  [LeadStageEnum.IN_PROGRESS]: (
    <InProgressIcon
      boxSize={3}
      color={stageColors[LeadStageEnum.IN_PROGRESS]}
    />
  ),
  [LeadStageEnum.BUSINESS]: (
    <BusinessIcon boxSize={3} color={stageColors[LeadStageEnum.BUSINESS]} />
  ),
  [LeadStageEnum.NO_BUSINESS]: (
    <NoBusinessIcon
      boxSize={3}
      color={stageColors[LeadStageEnum.NO_BUSINESS]}
    />
  ),
  [LeadStageEnum.DUPLICATE]: (
    <AllBusinessIcon boxSize={3} color={stageColors[LeadStageEnum.DUPLICATE]} />
  ),
};

export const LeadStageTag: React.FC<ILeadStageTagProps> = ({
  stage,
  ...rest
}) => {
  return (
    <Tag colorScheme={stageColors[stage]} {...rest}>
      <HStack>
        {stageIconMap[stage] || null}
        <TagLabel>{LeadStageLabel[stage]}</TagLabel>
      </HStack>
    </Tag>
  );
};
