import React, { useMemo } from "react";
import { GraphCard } from "components/stats/GraphCard";
import { TUserLoginStat } from "types/account-analytics.type";
import { TableV2 } from "components";
import { orderBy } from "lodash";
import { userLoginActivityColumnDef } from "./userLoginActivityColumnDef";

interface IProps {
  data: TUserLoginStat[];
}
const DEFAULT_PAGE_SIZE = 5;
const columns = userLoginActivityColumnDef();

export const UserLoginActivity: React.FC<IProps> = ({ data }) => {
  const tableData = useMemo(() => {
    return orderBy(data, ["lastLoginAt"], ["asc"]);
  }, [data]);

  return (
    <GraphCard title={`Least active users by login date`}>
      <TableV2<TUserLoginStat>
        globalFilterInputPlaceholder={"Search users"}
        pageSize={DEFAULT_PAGE_SIZE}
        columns={columns}
        dataSource={tableData}
        striped
      />
    </GraphCard>
  );
};
