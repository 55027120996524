import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import React from "react";
import { TUserStatsData } from "types/account-analytics.type";
import { Button } from "@chakra-ui/react";
import { LoginAtDateCell } from "../cells/LoginAtDateCell";
import { UserActivityCell } from "../cells/UserActivityCell";

export const userActivityByDayColumnDef = (
  handleRemoveUser: (id: number) => void
): ITableV2Column<TUserStatsData>[] => {
  return [
    {
      accessor: "fullName",
      width: "300px",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }: any) => (
        <DefaultRowCell>
          <UserActivityCell color={row.color} name={row.fullName} />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "total",
      Header: <DefaultHeaderCell>Leads Updated</DefaultHeaderCell>,
      Cell: ({ row }: any) => (
        <DefaultRowCell fontSize={"xs"} py={4}>
          {row.total}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "lastLoginAt",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Most Recent Login</DefaultHeaderCell>,
      Cell: ({ row }: any) => {
        return (
          <DefaultRowCell pl={1}>
            <LoginAtDateCell date={row.lastLoginAt} />
          </DefaultRowCell>
        );
      },
    },
    {
      accessor: "stats",
      width: "75px",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Actions</DefaultHeaderCell>,
      Cell: ({ row }: any) => {
        return (
          <DefaultRowCell pl={1}>
            <Button
              variant={"link"}
              fontWeight={"medium"}
              textDecoration={"underline"}
              colorScheme={"blue"}
              onClick={() => handleRemoveUser(row.id)}
            >
              Remove
            </Button>
          </DefaultRowCell>
        );
      },
    },
  ];
};
