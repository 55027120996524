import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  TLeadsByOffice,
  TOfficeLeaderboardDataPoint,
} from "types/account-analytics.type";
import { defaultTheme } from "theme/default.theme";
import { Dictionary } from "lodash";

export interface IOfficeLeaderboardStats {
  office: {
    id: number;
    name: string;
  };
  totalLeadsSum: number;
  averageResponseTime: number;
  conversionRate: number;
  overallScore: number;
}

export const noBusinessConversionWeights: Record<string, number> = {
  [LeadTypeEnum.Vendor]: 0.3,
  [LeadTypeEnum.Landlord]: 0.3,
  [LeadTypeEnum.Sale]: 0.5,
  [LeadTypeEnum.Let]: 0.6,
  [LeadTypeEnum.MortgageRequest]: 0.5,
};

export const getLeaderboardDataByLeaderboardType = (
  isOfficeGroups: boolean,
  dataFilteredByOffices: TOfficeLeaderboardDataPoint[],
  dataAggregatedByOfficeGroups: TOfficeLeaderboardDataPoint[]
) => {
  return isOfficeGroups ? dataAggregatedByOfficeGroups : dataFilteredByOffices;
};

export const getLeadsDataByLeaderboardType = (
  isOfficeGroups: boolean,
  officeDataMap: Dictionary<TLeadsByOffice>,
  officeGroupDataMap: Dictionary<TLeadsByOffice>,
  selectedOfficeStats?: IOfficeLeaderboardStats | null
) => {
  if (!selectedOfficeStats) return;
  const { id } = selectedOfficeStats.office;
  if (isOfficeGroups) {
    return officeGroupDataMap[id];
  } else {
    return officeDataMap[id];
  }
};

export function getOfficeLeaderbordStats(
  data: TOfficeLeaderboardDataPoint[],
  visibleTypes: LeadTypeEnum[]
): {
  dataPoints: IOfficeLeaderboardStats[];
  bestMaxAverageResponseTimeDataPoint: IOfficeLeaderboardStats | null;
  bestConversionRateDataPoint: IOfficeLeaderboardStats | null;
  bestOverallDataPoint: IOfficeLeaderboardStats | null;
} {
  let bestMaxAverageResponseTimeDataPoint: IOfficeLeaderboardStats | null = null;
  let bestConversionRateDataPoint: IOfficeLeaderboardStats | null = null;
  let bestOverallDataPoint: IOfficeLeaderboardStats | null = null;

  const newDataPoints = data.map(dataPoint => {
    let responseTimeSum = 0;
    let businessSum = 0;
    let noBusinessSum = 0;
    let noBusinessWightedSum = 0;
    let totalSum = 0;
    let averageResponseTime = 0;
    let conversionRate = 0;
    let overallScore = 0;

    if (visibleTypes.length) {
      visibleTypes.forEach(type => {
        responseTimeSum += dataPoint?.stats?.[type]?.speedScoreSum || 0;
        totalSum += dataPoint?.stats?.[type]?.total || 0;

        businessSum += dataPoint?.stats?.[type]?.business || 0;

        const noBusiness = dataPoint?.stats?.[type]?.noBusiness || 0;
        noBusinessSum += noBusiness;
        noBusinessWightedSum +=
          noBusiness * (noBusinessConversionWeights[type] || 0);
      });

      // Speed Score Formula: SUM (All lead points) Divided By (Number of Leads
      averageResponseTime = (responseTimeSum / totalSum) * 20 || 0;

      // Business Won Score Formula:
      // (Total Sum of Leads in Business + Total Sum of  leads in no business * noBusinessConversionWeight)
      // / (Total Sum of  leads in business + Total Sum of  leads in no business)
      // * 100 added at the end to convert to %
      conversionRate =
        ((businessSum + noBusinessWightedSum) / (businessSum + noBusinessSum)) *
          100 || 0;

      // Overall Score Formula:  ((Business Won Score) + (Speed Score * 20)) / 2
      overallScore = (conversionRate + averageResponseTime) / 2;
    }

    const newDataPoint = {
      office: dataPoint.office,
      totalLeadsSum: totalSum,
      averageResponseTime: Math.round(averageResponseTime) || 0,
      conversionRate: Math.round(conversionRate) || 0,
      overallScore: Math.round(overallScore) || 0,
    };

    if (
      !bestMaxAverageResponseTimeDataPoint ||
      bestMaxAverageResponseTimeDataPoint.averageResponseTime <
        newDataPoint.averageResponseTime
    ) {
      bestMaxAverageResponseTimeDataPoint = newDataPoint;
    }

    if (
      !bestConversionRateDataPoint ||
      bestConversionRateDataPoint.conversionRate < newDataPoint.conversionRate
    ) {
      bestConversionRateDataPoint = newDataPoint;
    }

    if (
      !bestOverallDataPoint ||
      bestOverallDataPoint.overallScore < newDataPoint.overallScore
    ) {
      bestOverallDataPoint = newDataPoint;
    }

    return newDataPoint;
  });

  return {
    dataPoints: newDataPoints,
    bestMaxAverageResponseTimeDataPoint,
    bestConversionRateDataPoint,
    bestOverallDataPoint,
  };
}

export const getScoreColor = (score: number) => {
  const MID = 30;
  const HIGH = 50;

  if (score < MID) {
    return defaultTheme.colors.red[500];
  }

  if (score >= MID && score < HIGH) {
    return defaultTheme.colors.orange[500];
  }

  if (score >= HIGH) {
    return defaultTheme.colors.green[500];
  }

  return defaultTheme.colors.red[500];
};

export interface IDownloadableLeaderboardStats
  extends Omit<IOfficeLeaderboardStats, "office"> {
  office: string;
}
