import {
  AccountUserStatsExtended,
  TUserLeaderboardExtended,
} from "../types/account-analytics.type";
import { LeadTypeEnum } from "../enums/lead-type.enum";
import { noBusinessConversionWeights } from "./office-leaderboard.utils";

export type TUserLeaderboardStats = AccountUserStatsExtended & {
  totalLeadsSum: number;
  averageResponseTime: number;
  conversionRate: number;
  overallScore: number;
};

export function getUserLeaderbordStats(
  data: TUserLeaderboardExtended[],
  visibleTypes: LeadTypeEnum[]
): TUserLeaderboardStats[] {
  return data.map(dataPoint => {
    let responseTimeSum = 0;
    let businessSum = 0;
    let noBusinessSum = 0;
    let noBusinessWightedSum = 0;
    let totalSum = 0;
    let averageResponseTime = 0;
    let conversionRate = 0;
    let overallScore = 0;

    if (visibleTypes.length) {
      visibleTypes.forEach(type => {
        responseTimeSum += dataPoint?.stats?.[type]?.speedScoreSum || 0;
        totalSum += dataPoint?.stats?.[type]?.total || 0;

        businessSum += dataPoint?.stats?.[type]?.business || 0;

        const noBusiness = dataPoint?.stats?.[type]?.noBusiness || 0;
        noBusinessSum += noBusiness;
        noBusinessWightedSum +=
          noBusiness * (noBusinessConversionWeights[type] || 0);
      });

      // Speed Score Formula: SUM (All lead points) Divided By (Number of Leads
      averageResponseTime = (responseTimeSum / totalSum) * 20 || 0;

      // Business Won Score Formula:
      // (Total Sum of Leads in Business + Total Sum of  leads in no business * noBusinessConversionWeight)
      // / (Total Sum of  leads in business + Total Sum of  leads in no business)
      // * 100 added at the end to convert to %
      conversionRate =
        ((businessSum + noBusinessWightedSum) / (businessSum + noBusinessSum)) *
          100 || 0;

      // Overall Score Formula:  ((Business Won Score) + (Speed Score * 20)) / 2
      overallScore = (conversionRate + averageResponseTime) / 2;
    }

    const { stats, ...rest } = dataPoint;

    return {
      ...rest,
      totalLeadsSum: totalSum,
      averageResponseTime: Math.round(averageResponseTime) || 0,
      conversionRate: Math.round(conversionRate) || 0,
      overallScore: Math.round(overallScore) || 0,
    };
  });
}
