import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GraphCard } from "components/stats/GraphCard";
import {
  getUserLeaderbordStats,
  TUserLeaderboardStats,
} from "utils/user-leaderboard.utils";
import { TUserLeaderboardExtended } from "types/account-analytics.type";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { Box } from "@chakra-ui/react";
import { Legend } from "components/chart/Legend";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { typesColors } from "constants/colors";
import { orderBy } from "lodash";
import { TableV2 } from "components";
import { userLeaderboardColumnDef } from "./userLeaderboardColumnDef";

interface IProps {
  data: TUserLeaderboardExtended[];
}

const DEFAULT_PAGE_SIZE = 10;

export const UserLeaderboard: React.FC<IProps> = ({ data }) => {
  const [visibleTypes, setVisibleTypes] = useState<LeadTypeEnum[]>([]);

  const allTypes = useMemo(() => {
    const types = new Set<LeadTypeEnum>();
    data.forEach(dataPoint => {
      Object.keys(dataPoint.stats).forEach(key => {
        types.add(key as LeadTypeEnum);
      });
    });

    return types;
  }, [data]);

  useEffect(() => {
    setVisibleTypes(Array.from(allTypes));
  }, [allTypes]);

  const handleToggleType = useCallback(
    (type: LeadTypeEnum) => {
      if (visibleTypes.includes(type)) {
        setVisibleTypes(visibleTypes.filter(element => element !== type));
      } else {
        setVisibleTypes([...visibleTypes, type]);
      }
    },
    [visibleTypes]
  );

  const legend = useMemo(() => {
    return (
      <Legend<LeadTypeEnum>
        onItemClick={handleToggleType}
        items={Array.from(allTypes).map((type: any) => {
          return {
            key: type,
            label: getLeadTypeLabel(type),
            color: typesColors[type as LeadTypeEnum]?.statsColor || "#718096",
            toggled: visibleTypes.includes(type),
          };
        })}
      />
    );
  }, [allTypes, handleToggleType, visibleTypes]);

  const tableData = useMemo(() => {
    return orderBy(
      getUserLeaderbordStats(data, visibleTypes),
      dataPoint => dataPoint.overallScore,
      "desc"
    );
  }, [data, visibleTypes]);

  const columnDef = useMemo(() => {
    return userLeaderboardColumnDef();
  }, []);

  return (
    <GraphCard title={`Users leaderboard`}>
      <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
        {legend}
      </Box>
      <TableV2<TUserLeaderboardStats>
        globalFilterInputPlaceholder={"Search users"}
        dataSource={tableData}
        columns={columnDef}
        pageSize={DEFAULT_PAGE_SIZE}
        striped
      />
    </GraphCard>
  );
};
