import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";
import { UserLeaderboard } from "./UserLeaderboard/UserLeaderboard";
import { LeadsByUserByStage } from "./LeadsByUserByStage";
import { UserActivityByDay } from "./UserActivityByDay/UserActivityByDay";
import { UserLoginActivity } from "./UserLoginActivity/UserLoginActivity";

interface IProps {
  analyticsStore: AccountAnalyticsStore;
}

export const UserReportingContent: FC<IProps> = observer(
  ({ analyticsStore }) => {
    const { accountUserAnalyticsStore } = analyticsStore;
    const {
      userLeaderboardData,
      leadsByUser,
      usersLoginActivity,
    } = accountUserAnalyticsStore;

    return (
      <>
        <Box mb={6}>
          <UserLeaderboard data={userLeaderboardData} />
        </Box>
        <Box mb={6}>
          <LeadsByUserByStage data={leadsByUser} />
        </Box>
        <Box mb={6}>
          <UserActivityByDay
            accountUserAnalyticsStore={accountUserAnalyticsStore}
          />
        </Box>
        <Box>
          <UserLoginActivity data={usersLoginActivity} />
        </Box>
      </>
    );
  }
);
