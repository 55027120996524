import { formatDate } from "utils/date.utils";
import { DATE_TIME_DAY_DECORATOR_FORMAT } from "constants/date";
import { Box, Flex } from "@chakra-ui/react";
import { EmailLink, ExternalLink, LeadTypeTag } from "components";
import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  TAdditionalDataEnquiry,
  TLeadPerson,
  TLeadOffice,
  TLeadWithDetails,
} from "types/lead.type";

import { TPortalData } from "types/portal.type";
import { Dictionary } from "lodash";
import { TNormalizedLeadSourceData } from "types/lead-source.type";

import { LeadBlockHeader } from "../LeadBlockHeader";
import {
  LeadInfoRow,
  LeadInfoRowLabel,
  LeadInfoRowValue,
} from "../LeadInfoRow";
import { LeadBlockBody } from "../LeadBlockBody";
import { useMemo } from "react";
import moment from "moment";
import { getUserFullName } from "utils/account-user.utils";
import { PhoneLink } from "components/links/PhoneLink";
import CopyButton from "components/copy-button/CopyButton";
import { TLeadUrlSyncData } from "types/url-sync.type";

export interface ILeadBasicBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.BasicBlock;
  createdAt: string;
  source: LeadSourceEnum;
  type: LeadTypeEnum;
  person: TLeadPerson;
  office: TLeadOffice | null;
  leadReferredByAccount?: TLeadWithDetails["leadReferredByAccount"];
  leadReferredByOffice?: TLeadWithDetails["leadReferredByOffice"];
  portalsMap: Dictionary<TPortalData>;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
  accountLogo: string | null;
  journeys: string[];
  urlSync: TLeadUrlSyncData["url"] | undefined;
  enquiry?: TAdditionalDataEnquiry;
}

export const LeadBasicInfoBlock = leadBlockHoc<ILeadBasicBlockDefinition>(
  ({ leadBlockDefinition }) => {
    const displayDate = useMemo(() => {
      const leadCreatedAtMoment = moment(leadBlockDefinition.createdAt);
      return leadCreatedAtMoment.fromNow();
    }, [leadBlockDefinition.createdAt]);

    const personFullName = useMemo(() => {
      return (
        leadBlockDefinition.person?.fullName ||
        getUserFullName(
          leadBlockDefinition.person?.firstName,
          leadBlockDefinition.person?.lastName
        ) ||
        ""
      );
    }, [leadBlockDefinition.person]);

    const personPhone = useMemo(() => {
      return leadBlockDefinition.person?.phone || "";
    }, [leadBlockDefinition.person]);

    const personEmail = useMemo(() => {
      return leadBlockDefinition.person?.email || "";
    }, [leadBlockDefinition.person]);

    const preferredContactTime = useMemo(() => {
      return leadBlockDefinition.enquiry?.preferredContactTime || "";
    }, [leadBlockDefinition.enquiry]);

    const personAddress = useMemo(() => {
      if (leadBlockDefinition.source === LeadSourceEnum.ivt) {
        return leadBlockDefinition.enquiry?.address || "";
      }

      return (
        leadBlockDefinition.person?.address ||
        leadBlockDefinition.person?.postcode ||
        ""
      );
    }, [
      leadBlockDefinition.enquiry,
      leadBlockDefinition.person,
      leadBlockDefinition.source,
    ]);

    const marketingConsent = useMemo(() => {
      return !!leadBlockDefinition.enquiry?.gdprCheckbox ? "True" : "False";
    }, [leadBlockDefinition.enquiry?.gdprCheckbox]);

    const urlSync = useMemo(() => {
      if (leadBlockDefinition.urlSync) return leadBlockDefinition.urlSync;
    }, [leadBlockDefinition.urlSync]);

    const journeys = useMemo(() => {
      if (leadBlockDefinition.journeys?.length > 0) {
        return leadBlockDefinition.journeys?.join(", ");
      }
      return;
    }, [leadBlockDefinition.journeys]);

    return (
      <>
        <LeadBlockHeader>Lead details</LeadBlockHeader>
        <LeadBlockBody>
          <LeadInfoRow>
            <LeadInfoRowLabel>Type</LeadInfoRowLabel>
            <LeadInfoRowValue>
              <LeadTypeTag
                type={leadBlockDefinition.type}
                source={leadBlockDefinition.source}
              />
            </LeadInfoRowValue>
          </LeadInfoRow>
          <LeadInfoRow>
            <LeadInfoRowLabel>Date</LeadInfoRowLabel>
            <LeadInfoRowValue>
              <Flex alignItems={"center"} lineHeight={1}>
                {formatDate(
                  leadBlockDefinition.createdAt,
                  DATE_TIME_DAY_DECORATOR_FORMAT
                )}
                <Box ml={4} fontSize={"sm"} color={"leadpro.500"}>
                  {displayDate}
                </Box>
              </Flex>
            </LeadInfoRowValue>
          </LeadInfoRow>
          <LeadInfoRow>
            <LeadInfoRowLabel>Name</LeadInfoRowLabel>
            <LeadInfoRowValue>{personFullName}</LeadInfoRowValue>
            {!!personFullName && (
              <CopyButton
                valueToCopy={personFullName}
                ariaLabel={"copy-full-name"}
              />
            )}
          </LeadInfoRow>
          <LeadInfoRow>
            <LeadInfoRowLabel>Phone</LeadInfoRowLabel>
            <LeadInfoRowValue>
              <PhoneLink phone={personPhone} color={"leadpro.600"} />
            </LeadInfoRowValue>
            {!!personPhone && (
              <CopyButton valueToCopy={personPhone} ariaLabel={"copy-phone"} />
            )}
          </LeadInfoRow>
          <LeadInfoRow>
            <LeadInfoRowLabel>Email</LeadInfoRowLabel>
            <LeadInfoRowValue>
              {!!personEmail && (
                <EmailLink email={personEmail} color={"leadpro.600"} />
              )}
            </LeadInfoRowValue>
            {!!personEmail && (
              <CopyButton valueToCopy={personEmail} ariaLabel={"copy-email"} />
            )}
          </LeadInfoRow>
          <LeadInfoRow>
            <LeadInfoRowLabel>Address</LeadInfoRowLabel>
            <LeadInfoRowValue>{personAddress}</LeadInfoRowValue>
            {!!personAddress && (
              <CopyButton
                valueToCopy={personAddress}
                ariaLabel={"copy-address"}
              />
            )}
          </LeadInfoRow>
          <LeadInfoRow>
            <LeadInfoRowLabel>Contact time</LeadInfoRowLabel>
            <LeadInfoRowValue>{preferredContactTime}</LeadInfoRowValue>
            {!!preferredContactTime && (
              <CopyButton
                valueToCopy={preferredContactTime}
                ariaLabel={"copy-preferred-contact-time"}
              />
            )}
          </LeadInfoRow>
          {!!journeys && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Journeys</LeadInfoRowLabel>
              <LeadInfoRowValue>{journeys}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!urlSync && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Url Sync</LeadInfoRowLabel>
              <LeadInfoRowValue>
                <ExternalLink href={urlSync} label={"Open lead "} />
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}

          <LeadInfoRow>
            <LeadInfoRowLabel>Marketing consent</LeadInfoRowLabel>
            <LeadInfoRowValue>{marketingConsent}</LeadInfoRowValue>
          </LeadInfoRow>
        </LeadBlockBody>
      </>
    );
  }
);
