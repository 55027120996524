import { getLeadTypeLabel } from "utils/lead-type.utils";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { PivotStatTable } from "components/table/PivotStatTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GraphCard } from "components/stats/GraphCard";
import { typesColors } from "constants/colors";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { groupBy } from "lodash";
import { Legend } from "components/chart/Legend";
import { Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@chakra-ui/react";
import { LeadStageTag, LeadTypeTag } from "components/tags";
import { LeadStageLabel } from "constants/lead-stage-label";
import { TLeadsByUser } from "types/account-analytics.type";

interface IProps {
  data: TLeadsByUser[];
}

export const LeadsByUserByStage: React.FC<IProps> = ({ data }) => {
  const [visibleTypes, setVisibleTypes] = useState<string[]>([]);
  const allTypes = useMemo(() => {
    const types = new Set<string>();
    data.forEach(dataPoint => {
      dataPoint.stats.forEach(stat => {
        types.add(stat.type);
      });
    });

    return types;
  }, [data]);

  useEffect(() => {
    setVisibleTypes(Array.from(allTypes));
  }, [allTypes]);

  const generateColumnByStage = useCallback(
    (stage: LeadStageEnum) => {
      return {
        Header: (
          <Box display={"flex"} height={"100%"} alignItems={"center"}>
            <LeadStageTag stage={stage} />
          </Box>
        ),
        id: LeadStageLabel[stage],
        accessor: (originalRow: TLeadsByUser) =>
          originalRow.stats.reduce((accumulator, currentValue) => {
            if (!visibleTypes.includes(currentValue.type)) {
              return accumulator;
            }

            return currentValue.stage === stage
              ? accumulator + currentValue.count
              : accumulator;
          }, 0),
        Cell: ({ row }: { row: Row<TLeadsByUser> }) => {
          return (
            <Box display={"flex"} height={"100%"} alignItems={"center"}>
              <Box>{row.values[LeadStageLabel[stage]]}</Box>
            </Box>
          );
        },
        width: 100,
      };
    },
    [visibleTypes]
  );

  const handleToggleType = useCallback(
    (type: string) => {
      if (visibleTypes.includes(type)) {
        setVisibleTypes(visibleTypes.filter(element => element !== type));
      } else {
        setVisibleTypes([...visibleTypes, type]);
      }
    },
    [visibleTypes]
  );

  const legend = useMemo(() => {
    return (
      <Legend
        onItemClick={handleToggleType}
        items={Array.from(allTypes).map((type: any) => {
          return {
            key: type,
            label: getLeadTypeLabel(type),
            color: typesColors[type as LeadTypeEnum]?.statsColor || "#718096",
            toggled: visibleTypes.includes(type),
          };
        })}
      />
    );
  }, [allTypes, handleToggleType, visibleTypes]);

  const table = useMemo(() => {
    return (
      <PivotStatTable<TLeadsByUser>
        getSubRows={row => {
          if (!row.id) return null;
          const subRows: any[] = [];
          const groupedByType = groupBy(row.stats, stat => stat.type);
          Object.keys(groupedByType).forEach(typeKey => {
            if (visibleTypes.includes(typeKey)) {
              subRows.push({
                email: typeKey,
                stats: groupedByType[typeKey].map(stat => ({
                  count: stat.count,
                  type: stat.type,
                  stage: stat.stage,
                })),
              });
            }
          });

          return subRows;
        }}
        data={data}
        defaultSort={[
          {
            id: LeadStageLabel[LeadStageEnum.UNREAD],
            desc: true,
          },
        ]}
        columns={[
          {
            id: "expander",
            Header: ({
              getToggleAllRowsExpandedProps,
              isAllRowsExpanded,
            }: any) => {
              return (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <div {...getToggleAllRowsExpandedProps()}>
                    {isAllRowsExpanded ? (
                      <FontAwesomeIcon icon={faAngleDown} fontSize={14} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronRight} fontSize={12} />
                    )}
                  </div>
                </Box>
              );
            },
            Cell: ({ row }: any) =>
              row.canExpand ? (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <div>
                    {row.isExpanded ? (
                      <FontAwesomeIcon icon={faAngleDown} fontSize={14} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronRight} fontSize={12} />
                    )}
                  </div>
                </Box>
              ) : null,
            width: 20,
          },
          {
            Header: (
              <Box display={"flex"} height={"100%"} alignItems={"center"}>
                <div>User</div>
              </Box>
            ),
            accessor: "email",
            Cell: ({ row }: any) => {
              return (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  {row.original.id && <div>{row.original.email}</div>}
                  {!row.original.id && (
                    <LeadTypeTag type={row.original.email} />
                  )}
                </Box>
              );
            },
          },
          generateColumnByStage(LeadStageEnum.UNREAD),
          generateColumnByStage(LeadStageEnum.IN_PROGRESS),
          generateColumnByStage(LeadStageEnum.BUSINESS),
          generateColumnByStage(LeadStageEnum.NO_BUSINESS),
        ]}
      />
    );
  }, [data, generateColumnByStage, visibleTypes]);

  return (
    <GraphCard title={`Leads by user by stage`}>
      <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
        {legend}
      </Box>
      {table}
    </GraphCard>
  );
};
