import { Box, Heading, Button, useToast } from "@chakra-ui/react";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import queryString from "query-string";
import {
  ApiMessageStack,
  EmailLink,
  FormControlV2,
  NavigationPrompt,
} from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "constants/routes";
import { useLocation } from "react-router-dom";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { getLeadProSignUpTokenPayload } from "utils/jwt.utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTurnstileV2 } from "utils/react-hooks/useTurnstileV2.hook";

type TFieldValues = {
  password: string;
};

const initialValues: TFieldValues = {
  password: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required(REQUIRED_FIELD),
});

export const UserRegistration = observer(() => {
  const [status, setStatus] = useState<ApiRequestStatusEnum | null>(null);
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const toast = useToast();
  const { turnstileToken } = useTurnstileV2();
  const {
    authStore: {
      leadProAuthStore: { verifySignupToken, register, login },
    },
  } = useAppStore();
  const location = useLocation();
  const token = useMemo(() => {
    const t = queryString.parse(location.search)?.t as string;
    return t === "undefined" ? "" : t;
  }, [location]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async ({ password }: TFieldValues) => {
      try {
        if (!!turnstileToken) {
          await register(token, password, turnstileToken);
          const tokenPayload = getLeadProSignUpTokenPayload(token);
          await login(tokenPayload.email, password, turnstileToken);
          setStatus(ApiRequestStatusEnum.SUCCESS);
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [register, token, toast, login, turnstileToken]
  );

  const renderMissingToken = () => {
    return (
      <Box>
        <Box mb={4}>
          No user with your email is currently registered on the LeadPro
          dashboard.
        </Box>
        <Box>
          Please contact your office manager to be added, or email{" "}
          <EmailLink email={"contact@lead.pro"} /> to request dashboard access
          to your account.
        </Box>
      </Box>
    );
  };

  const renderInvalidToken = () => {
    return (
      <NavigationPrompt
        text={`Your account creation has expired.\nPlease register your account again and verify your email within 4 weeks of creating you account.`}
        ctaText={"Sign up again."}
        ctaNavigateTo={SIGNUP_ROUTE}
      />
    );
  };

  const renderValidToken = useCallback(() => {
    if (status !== ApiRequestStatusEnum.SUCCESS) {
      return (
        <>
          <Box mb={3}>
            <FormControlV2<TFieldValues>
              name={"password"}
              control={control}
              isDisabled={isSubmitting}
              type={FormControlsTypeEnum.PASSWORD}
              additionalProps={{
                placeholder: "Set password",
              }}
            />
          </Box>
          <Button
            type={"submit"}
            width={"full"}
            colorScheme={"blue"}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Activate account
          </Button>
        </>
      );
    }

    return (
      <NavigationPrompt
        text={
          "Your account was successfully activated. You should be automatically logged in soon."
        }
        ctaText={"Go to login"}
        ctaNavigateTo={LOGIN_ROUTE}
      />
    );
  }, [control, isSubmitting, status]);

  useEffect(() => {
    const checkToken = async () => {
      try {
        await verifySignupToken(token);
        setTokenIsValid(true);
      } catch (e) {
        setTokenIsValid(false);
      }
    };

    if (token) {
      checkToken();
    }
  }, [verifySignupToken, location, token]);

  return (
    <Box maxW="300px" width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading fontSize="2xl" textAlign="center" lineHeight="none" mb={6}>
          Activate your account
        </Heading>
        {!!token
          ? tokenIsValid
            ? renderValidToken()
            : renderInvalidToken()
          : renderMissingToken()}
      </form>
    </Box>
  );
});
