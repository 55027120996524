import { observer } from "mobx-react";
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccountUserAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsUser.store";
import { MultiSelectInput } from "components";
import { SelectInputSizes } from "components/select-input/select-input-size";
import { TSelectValue } from "types/select-input.type";

interface IProps {
  accountUserAnalyticsStore: AccountUserAnalyticsStore;
  selectedUsers: number[];
  onChange: (value: number[]) => void;
}

export const UserActivityUserFilter: FC<IProps> = observer(
  ({ accountUserAnalyticsStore, onChange, selectedUsers }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { accountUserOptions } = accountUserAnalyticsStore;

    const handleChange = useCallback(
      (values: TSelectValue<string | number>[]) => {
        onChange(values as number[]);
      },
      [onChange]
    );

    const clearAll = useCallback(() => {
      onChange([]);
      onClose();
    }, [onChange, onClose]);

    const addAll = useCallback(() => {
      onChange(accountUserOptions.map(option => option.value));
      onClose();
    }, [onChange, accountUserOptions, onClose]);

    return (
      <Popover
        closeOnBlur={true}
        isLazy={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button
            width={"200px"}
            leftIcon={<FontAwesomeIcon icon={faArrowDown} />}
            borderRadius={"sm"}
            colorScheme={"blue"}
          >
            Change Users
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width={"645px"}>
            <PopoverArrow />
            <PopoverHeader
              fontSize={"md"}
              fontWeight={700}
              color={"leadpro.800"}
            >
              Add or remove users
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody pt={5}>
              <VStack alignItems={"flex-start"} spacing={5} pb={2}>
                <Box color={"leadpro.500"} fontSize={"sm"}>
                  Add or remove users for reporting by user by activity by day.
                </Box>
                <Box width={"100%"} height={"100px"}>
                  <MultiSelectInput<number | string>
                    height={"100%"}
                    size={SelectInputSizes.SMALL}
                    value={selectedUsers}
                    onChange={handleChange}
                    options={accountUserOptions}
                    placeholder={`Add User's`}
                  />
                </Box>
                <HStack spacing={5}>
                  <Button
                    variant={"link"}
                    color={"leadpro.800"}
                    onClick={addAll}
                  >
                    Add all
                  </Button>
                  <Button
                    variant={"link"}
                    color={"leadpro.800"}
                    onClick={clearAll}
                  >
                    Clear all
                  </Button>
                </HStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);
