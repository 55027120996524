import { Box } from "@chakra-ui/react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";

const env = runtimeEnv();
const REACT_APP_RECAPTCHA_SITEKEY = env.REACT_APP_RECAPTCHA_SITEKEY;

export const TurnstileV2Context = React.createContext<
  { turnstileToken: string | null } | undefined
>(undefined);

interface IProps {
  children: React.ReactNode;
}

export const TurnstileV2 = ({ children }: IProps) => {
  const [token, setToken] = useState<string | null>(null);
  const turnstile = useTurnstile();
  const { pathname } = useLocation();

  useEffect(() => {
    if (turnstile) {
      turnstile.reset();
    }
  }, [pathname, turnstile]);

  return (
    <TurnstileV2Context.Provider value={{ turnstileToken: token }}>
      {children}
      <Box mt={4}>
        <Turnstile
          sitekey={REACT_APP_RECAPTCHA_SITEKEY}
          theme="light"
          onVerify={token => {
            setToken(token);
          }}
          fixedSize
        />
      </Box>
    </TurnstileV2Context.Provider>
  );
};

export default TurnstileV2;
